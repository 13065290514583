/* eslint-disable */

function titleize(str) {
  // Split the string into words using a regular expression
  // that matches one or more whitespace characters.
  const words = str.split(/\s+/);

  // Initialize an array to store the titleized words.
  const titleizedWords = [];

  // Loop through each word and titleize it.
  for (const word of words) {
    // Convert the first letter to uppercase and the rest to lowercase.
    const titleizedWord =
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

    // Push the titleized word to the array.
    titleizedWords.push(titleizedWord);
  }

  // Join the titleized words back into a single string.
  const titleizedString = titleizedWords.join(" ");

  return titleizedString;
}

function getDocumentTerms() {
  if (!lease) {
    return;
  }

  const root = document.querySelector(".document");
  const conditions = root.querySelectorAll("[lease-if]");
  const variables = root.querySelectorAll("lease-var");
  const building = window.lease.buildingInfo;

  const result = {};

  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i];
    const parts = [
      condition.getAttribute("hl"),
      condition.getAttribute("hl2"),
      condition.getAttribute("h3"),
      condition.getAttribute("h4"),
      condition.getAttribute("h5"),
      condition.getAttribute("h6"),
      condition.getAttribute("hl7"),
      condition.getAttribute("hl8"),
      condition.getAttribute("hl9"),
      condition.getAttribute("hl10"),
      condition.getAttribute("hl11"),
      condition.getAttribute("hl12"),
      condition.getAttribute("hl13"),
      condition.getAttribute("hl14"),
      condition.getAttribute("hl15")
    ];

    for (let j = 0; j < parts.length; j++) {
      const part = parts[j];

      if (part) {
        const value = _.get(window.lease, part.replace("lease.", ""));
  
        if (value !== null && value !== undefined && !part.endsWith(".length")) {
          _.set(result, part.replace("lease.", ""), value);
          break;
        }
      }
    }
  }

  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i];
    const parts = [
      variable.getAttribute("hl"),
      variable.getAttribute("hl2"),
      variable.getAttribute("h3"),
      variable.getAttribute("h4"),
      variable.getAttribute("h5"),
      variable.getAttribute("h6"),
      variable.getAttribute("hl7"),
      variable.getAttribute("hl8"),
      variable.getAttribute("hl9"),
      variable.getAttribute("hl10"),
      variable.getAttribute("hl11"),
      variable.getAttribute("hl12"),
      variable.getAttribute("hl13"),
      variable.getAttribute("hl14"),
      variable.getAttribute("hl15")
    ];

    for (let j = 0; j < parts.length; j++) {
      const part = parts[j];

      if (part) {
        const value = _.get(window.lease, part.replace("lease.", ""));
  
        if (value !== null && value !== undefined && !part.endsWith(".length")) {
          _.set(result, part.replace("lease.", ""), value);
          break;
        }
      }
    }
  }

  result.building = {};

  if (building.city) {
    result.building.city = building.city;
  }
  if (building.state) {
    result.building.state = building.state;
  }
  if (building.county) {
    result.building.county = building.county;
  }
  if (building.zipCode) {
    result.building.zipCode = building.zipCode;
  }
  if (building.displayName) {
    result.building.displayName = building.displayName;
  }
  if (building.landlordName) {
    result.building.landlordName = building.landlordName;
  }
  if (building.streetAddress) {
    result.building.streetAddress = building.streetAddress;
  }
  if (building.landlordEntityType) {
    result.building.landlordEntityType = building.landlordEntityType;
  }
  if (building.landlordStateOfFormation) {
    result.building.landlordStateOfFormation = building.landlordStateOfFormation;
  }

  return result;
}

function getDocumentText() {
  if (!lease) {
    return;
  }

  const root = document.querySelector(".document");

  if (root) {
    const text = root.innerText;
  
    return text
      .replace(/\u200B/g, "") // ZERO WIDTH SPACE
      .replace(/\u200D/g, "") // ZERO WIDTH JOINER
      .replace(/\ufeff/g, ""); // ZERO WIDTH NO-BREAK SPACE;
  }
}

function getContext() {
  const selection = rangy.getSelection();

  if (selection) {
    return selection.toString();
  }

  return null;
}

(function() {
  function AutopilotpManager() {
    let $scope = null;

    this.initialized = false;

    this.init = function(_scope) {
      $scope = _scope;

      if (this.initialized) {
        return;
      }

      try {
        if (!lease) {
          return;
        }

        if (window?.user?.company?.companyProfile?.hasCoPilot) {
          const autopilotFrame = document.getElementById("autopilot");
          const params = new URLSearchParams();
          const workspaceId = window.user.company.name;
          params.append("workspaceId", workspaceId);
          const userId = window.user.email;
          params.append("userId", userId);
          const reference = document.location.href;
          params.append("reference", reference);
          const name = document.querySelector(".document-info").innerText;
          params.append("name", name);

          autopilotFrame.setAttribute(
            "src",
            `${window._autopilot_base_url}/?${params.toString()}`
          );

          window.addEventListener(
            "message",
            event => {
              if (event.origin !== window._autopilot_base_url) {
                return;
              }

              switch (event.data.type) {
                case "INSERT": {
                  this.insert(event);
                  break;
                }
                case "COPY": {
                  this.copy(event);
                  break;
                }
              }
            },
            false
          );

          this.initialized = true;

          setTimeout(() => {
            this.updateDocumentContext();
          }, 3000);
        }
      } catch (e) {
        console.log(e);
        // Do nothing.
      }
    };

    this.updateDocumentContext = function() {
      try {
        const iframe = document.getElementById("autopilot");

        if (iframe) {
          const terms = getDocumentTerms();
          const doc = getDocumentText();
  
          const message = {
            type: "UPDATE_DOCUMENT_CONTEXT",
            payload: {
              terms,
              doc,
            }
          };
  
          iframe.contentWindow.postMessage(message, window._autopilot_base_url);
        }
      } catch(ex) {
        Raven.captureException(ex);
      }
    };

    this.updatePromptContext = function() {
      try {
        const iframe = document.getElementById("autopilot");

        if (iframe) {
          const context = getContext();
  
          const message = {
            type: "UPDATE_PROMPT_CONTEXT",
            payload: {
              context,
            }
          };
  
          iframe.contentWindow.postMessage(message, window._autopilot_base_url);
        }
      } catch(ex) {
        Raven.captureException(ex);
      }
    }

    this.insert = function(event) {
      const { text } = event.data.payload;

      if (text) {
        let cleanText = text;
        
        cleanText = cleanText.replace(/{{.*?}}/g, '');

        cleanText = DOMPurify.sanitize(cleanText, {
          ALLOWED_TAGS: [],
          ALLOWED_ATTR: [],
        });

        clipboardManager.insertText(cleanText);
      }
    };

    this.copy = function(event) {
      const { text } = event.data.payload;

      if (text) {
        const ClipboardItemData = {
          "text/plain": new Blob([text], { type: "text/plain" })
        };
        const data = [new ClipboardItem(ClipboardItemData)];

        navigator.clipboard.write(data);
      }
    };

    this.getDocumentTerms = function() {
      return getDocumentTerms();
    }

    this.getDocumentText = function() {
      return getDocumentText();
    }

    this.getContext = function() {
      return getContext();
    }
  }

  window.autopilotManager = new AutopilotpManager();
})();
